<template>
  <div>
    <v-container>
      <TheDrawerInfo
        v-if="flashcardsSimulator[this.activeId]"
        :area="flashcardsSimulator[this.activeId].area"
        :especialidad="flashcardsSimulator[this.activeId].especialidad"
        :tema="tema()"
      />
    </v-container>
    <v-container style="background: #EFF3F4" class="d-flex justify-center ">
      <v-col cols="12" md="11" align-self="center">
        <FlashcardHeader class="d-flex d-md-none" @click="$router.go(-1)">
          {{ tema() }}
        </FlashcardHeader>
        <div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'mb-0': ''">
          <div
            v-for="(category, index) in tags()"
            :key="index"
            class="chips__wraper px-5 py-2 mr-2"
          >
            <p class="chips__text my-auto">{{ category }}</p>
          </div>
        </div>
        <v-slide-x-transition>
          <div v-if="!loading" style="width: 104%; margin-left: -2% !important;" class="ma-0 pa-0 flashcard__wraper">
            <FlashcardsList
              :height="$vuetify.breakpoint.mobile ? 470: 440"
              :flashcards="flashcardsSimulator"
              @selectState="selectState"
            />
          </div>
          <div v-else>
            <br>
            <div class="progress__wrapper d-flex justify-center">
              <v-progress-circular indeterminate/>
            </div>
            <br><br>
          </div>
        </v-slide-x-transition>
        <div :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column': 'd-flex'">
          <div flat class="timebar mr-5 d-flex justify-center align-center">
            {{ timer }}
          </div>
          <!-- <FlashcardLevelBar
            :progress="progress"
            :level="nivel"
          /> -->
          <FlashcardLevelBar
            :progress="progress"
            :level="nivel"
          />
        </div>
        <v-card class="mt-5 pa-5 py-8 d-flex d-md-none flashcard-description__wraper" min-height="350">
          <FlashcardDescription :flashcardInfo="historialResponse"/>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import TheDrawerInfo from '@/components/TheDrawerInfo'
import FlashcardsList from '@/components/flashcard/FlashcardsList.vue'
import FlashcardLevelBar from '@/components/flashcard/FlashcardLevelBar.vue'
import FlashcardDescription from '@/components/flashcard/FlashcardDescription'
import FlashcardHeader from '@/components/flashcard/FlashcardHeader'
import moment from 'moment'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'FlashcardSimulator',

  components: {
    TheDrawerInfo,
    FlashcardsList,
    FlashcardLevelBar,
    FlashcardDescription,
    FlashcardHeader
  },

  data: () => ({
    categories: ['ENAM', 'Internado'],
    activeId: 0,
    timer: '',
    levelBar: 0,
    offset: 20,
    level: 1,
    loading: false,
    flashcardsSimulator: [],
    timeStop: false,
    modo: 'NORMAL',
    flashcardsSummary: null
  }),

  computed: {
    ...mapState('flashcard', ['pendingFlashcards', 'historialResponse', 'experience']),
    ...mapState('profile', ['user']),
    ...mapGetters('profile', ['getExperience']),
    progress () {
      if (!this.flashcardsSummary) { return 0 }
      const percentage = this.flashcardsSummary.cantidad_flashcards_aprendidas / this.flashcardsSummary.cantidad_flashcards_total * 100
      return percentage.toFixed(2)
    },
    flashcardIndex () {
      if (this.flashcardsSimulator.length && this.flashcardsSimulator[this.activeId] !== undefined) {
        return this.flashcardsSimulator[this.activeId].id
      } else {
        return null
      }
    },
    nivel () {
      return `${this.progress}% Aprendido`
    }
  },
  methods: {
    tema () {
      if (this.flashcardsSimulator[this.activeId] !== undefined) {
        return this.flashcardsSimulator[this.activeId].tema
      } else {
        return null
      }
    },
    tags () {
      if (this.flashcardsSimulator[this.activeId] !== undefined) {
        return this.flashcardsSimulator[this.activeId].tags
      } else {
        return null
      }
    },
    ...mapActions('flashcard', ['fetchPendingFlashcards', 'fetchHistorialOfResponses']),
    ...mapActions('profile', ['fetchUser']),
    ...mapMutations('flashcard', ['SET_CURRENT_FLASHCARD']),
    async selectState (state) {
      try {
        if (this.modo !== 'REPASO') {
          if (this.$route.params.tipo === 'mis-flashcards') {
            await axios.post(`api/flashcards/mis_flashcards/${this.flashcardIndex}/responder/`, { respuesta: state })
          } else {
            await axios.post(`api/flashcards/flashcards/${this.flashcardIndex}/responder/`, { respuesta: state })
          }
        }
        this.adjustPercentage(state)
        this.levelBar += 1
        if (this.levelBar === 20) {
          this.levelBar = 0
          await this.fetchUser()
          await this.assignLvl()
        }
        if (this.flashcardsSimulator.length - 1 > this.activeId) {
          // If there's another flashcard to respond
          this.activeId += 1
        } else {
          const tipo = this.$route.params.tipo
          const ids = this.$route.params.ids
          await this.flashcardsBatching(tipo, ids)
          this.activeId = 0
        }
      } catch (e) {
        this.$root.$confirm({
          message: e.response,
          acceptText: 'Ok'
        })
      }
    },
    adjustPercentage (state) {
      if (!this.flashcardsSummary || state === 'DIFICIL' || state === 'NO_LA_SE' || this.modo === 'REPASO') { return }
      this.flashcardsSummary.cantidad_flashcards_aprendidas += 1
    },
    async flashcardsBatching (tipo, ids) {
      let items = []
      items = ids.toString().split(',').map(item => parseInt(item))
      if (tipo === 'temas') {
        const flashcardsResponse = await axios.post('api/flashcards/flashcards/por_tema/', { temas_ids: items, modo: this.modo })
        this.flashcardsSimulator = flashcardsResponse.data
      } else if (tipo === 'especialidades') {
        const flashcardsResponse = await axios.post('api/flashcards/flashcards/por_especialidad/', { especialidades_ids: items })
        this.flashcardsSimulator = flashcardsResponse.data
      } else if (tipo === 'mis-flashcards') {
        const flashcardsResponse = await axios.get(`api/flashcards/mis_mazos/${ids}/pendientes/?modo=${this.modo}`)
        this.flashcardsSimulator = flashcardsResponse.data
      } else {
        const flashcardsResponse = await axios.post('api/flashcards/flashcards/por_area/', { areas_ids: items })
        this.flashcardsSimulator = flashcardsResponse.data
      }
      if (this.flashcardsSimulator.length > 0 && this.flashcardIndex !== null) {
        // Cuándo solo queda una flashcard y la respondemos con no lo se o dificil, el id flashcard no cambia
        // por lo tanto, no trae su historial, con esto traeremos siempre su historial
        // aunque cuándo traigamos mas flahscards del backend haga 2 peticiones
        this.fetchHistorialOfResponses(this.flashcardIndex)
        // Setereamos el current flashcard al primer elemento
        this.SET_CURRENT_FLASHCARD(this.flashcardsSimulator[0])
      } else {
        if (this.flashcardsSimulator.length === 0) {
          if (tipo === 'temas' || tipo === 'mis-flashcards') {
            await this.confirmRepasoMode(tipo, ids)
          } else {
            await this.$root.$confirm({
              message: 'Ups! se terminaron las flashcards',
              acceptText: 'Ok'
            })
          }
        }
      }
    },
    async confirmRepasoMode (tipo, ids) {
      // Si no es premium verificar si existen flashcards en ese tema
      const items = ids.toString().split(',').map(item => parseInt(item))
      let thereIsFlashcardsAvailable = false
      if (tipo === 'temas') {
        const flashcardsResponse = await axios.post('api/flashcards/flashcards/por_tema/', { temas_ids: items, modo: 'REPASO' })
        thereIsFlashcardsAvailable = !!flashcardsResponse.data.length
      } else {
        const flashcardsResponse = await axios.get(`api/flashcards/mis_mazos/${ids}/pendientes/?modo=REPASO`)
        thereIsFlashcardsAvailable = !!flashcardsResponse.data.length
      }
      if (!thereIsFlashcardsAvailable) {
        await this.$root.$confirm({
          message: 'Ups! se terminaron las flashcards',
          acceptText: 'Ok'
        })
        if (tipo === 'mis-flashcards') {
          await this.$router.push({ name: 'Mazos' })
        } else {
          await this.$router.push({ name: 'UnitsAreas' })
        }
        return
      }
      // Si es premium significa que el tema si o si tiene flashcards para repasar
      // entonces se debe preguntar al usuario si quiere dar un repaso
      const confirm = await this.$root.$confirm({
        title: 'Ups!',
        message: 'Se terminaron las flashcards. ¿Deseas hacer un repaso?',
        acceptText: 'Si',
        cancelText: 'No'
      })
      if (confirm) {
        this.modo = 'REPASO'
        await this.flashcardsBatching(tipo, ids)
      } else {
        if (tipo === 'mis-flashcards') {
          await this.$router.push({ name: 'Mazos' })
        } else {
          await this.$router.push({ name: 'UnitsAreas' })
        }
      }
    },
    assignLvl () {
      this.level = parseInt(this.getExperience / 20)
      this.levelBar = this.getExperience % 20
    },
    setTimer () {
      const today = new Date()
      var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
      const end = moment(myToday)
      this.timer = end.format('mm:ss')
      const self = this
      setInterval(function () {
        end.add(1, 'seconds')
        self.timer = end.format('mm:ss')
      }, 1000)
    },
    async fetchMaterizedFlashcardsPercent (tipo, ids) {
      let items = []
      items = ids.toString().split(',').map(item => parseInt(item))
      if (tipo === 'temas') {
        const response = await axios.post('api/flashcards/flashcards/resumen_por_tema/', { temas_ids: items, modo: this.modo })
        this.flashcardsSummary = response.data
      } else if (tipo === 'especialidades') {
        const response = await axios.post('api/flashcards/flashcards/resumen_por_especialidad/', { especialidades_ids: items })
        this.flashcardsSummary = response.data
      } else if (tipo === 'mis-flashcards') {
        const response = await axios.get(`api/flashcards/mis_mazos/${ids}/resumen_pendientes/?modo=${this.modo}`)
        this.flashcardsSummary = response.data
      } else {
        const response = await axios.post('api/flashcards/flashcards/resumen_por_area/', { areas_ids: items })
        this.flashcardsSummary = response.data
      }
    }
  },
  watch: {
    flashcardIndex: function () {
      if (this.flashcardIndex) {
        this.fetchHistorialOfResponses(this.flashcardIndex)
      }
    },
    activeId: {
      immediate: true,
      handler: function (value) {
        if (value === undefined || value === null || this.flashcardsSimulator.length === 0) {
          this.SET_CURRENT_FLASHCARD(null)
        } else {
          this.SET_CURRENT_FLASHCARD(this.flashcardsSimulator[value])
        }
      }
    }
  },
  mounted () {
    this.setTimer()
  },
  async created () {
    this.loading = true
    const tipo = this.$route.params.tipo
    let ids = 0
    if (tipo === 'mis-flashcards') {
      ids = this.$route.params.ids
    } else {
      ids = this.$route.params.ids + '' // Convertimos a string, en caso se envie solo 1 id como param
    }
    try {
      await this.fetchUser()
      await this.flashcardsBatching(tipo, ids)
      await this.fetchMaterizedFlashcardsPercent(tipo, ids)
      await this.assignLvl()
    } catch (e) {
      this.$root.$confirm({
        message: 'Ocurrió un error inesperado, por favor actualice la página.',
        acceptText: 'Ok'
      })
    }
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.flashcard-description {
  &__wraper {
    cursor: pointer;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px !important;
  }
}

.chips__wraper {
  background: #FFFFFF;
  border: 1px solid #E5E5E5 !important;
  border-radius: 100px;
  cursor: pointer;
}

.timebar {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
  width: 23%;
}

@media (max-width: 960px) {
  .timebar {
    width: 100% !important;
    height: 35px !important;
    order: 2;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }
  .flashcard {
    &__wraper {
      height: 480px;
    }
  }
}
@media (max-width: 600px) {
  .timebar {
    margin-top: 15px;
  }
}
</style>
